
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/form/input/BasicUsage.vue";
import EUIDisabled from "@/views/resources/documentation/element-ui/form/input/Disabled.vue";
import EUIClearable from "@/views/resources/documentation/element-ui/form/input/Clearable.vue";
import EUIPasswordBox from "@/views/resources/documentation/element-ui/form/input/PasswordBox.vue";
import EUIInputWithIcon from "@/views/resources/documentation/element-ui/form/input/InputWithIcon.vue";
import EUITextarea from "@/views/resources/documentation/element-ui/form/input/Textarea.vue";
import EUIAutosizeTextarea from "@/views/resources/documentation/element-ui/form/input/AutosizeTextarea.vue";
import EUIMixedInput from "@/views/resources/documentation/element-ui/form/input/MixedInput.vue";
import EUISizes from "@/views/resources/documentation/element-ui/form/input/Sizes.vue";
import EUIAutocomplete from "@/views/resources/documentation/element-ui/form/input/Autocomplete.vue";
import EUICustomTemplate from "@/views/resources/documentation/element-ui/form/input/CustomTemplate.vue";
import EUIRemoteSearch from "@/views/resources/documentation/element-ui/form/input/RemoteSearch.vue";
import EUILimitLength from "@/views/resources/documentation/element-ui/form/input/LimitLength.vue";

export default defineComponent({
  name: "input",
  components: {
    EUIBasicUsage,
    EUIDisabled,
    EUIClearable,
    EUIPasswordBox,
    EUIInputWithIcon,
    EUITextarea,
    EUIAutosizeTextarea,
    EUIMixedInput,
    EUISizes,
    EUIAutocomplete,
    EUICustomTemplate,
    EUIRemoteSearch,
    EUILimitLength
  },
  setup() {
    setCurrentPageTitle("Input");
  }
});
