
import { defineComponent, ref } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code4 } from "./data";

export default defineComponent({
  name: "password-box",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      input: ref(""),
      code4
    };
  }
});
